import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { InputPara } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-todays-visits',
  templateUrl: './todays-visits.component.html',
  styleUrls: ['./todays-visits.component.css']
})
export class TodaysVisitsComponent implements OnInit {

  FieldName: any;
  FieldValue: any;
  UserID: any;
  DataType: any;
  VData: any;
  UserType: any;
  TokenNo: any;
  deviceType: any;
  device: any;
  lat: any;
  lng: any;
  input: InputPara = new InputPara();
  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, private router: Router,
    private headerService: HeaderService, private mds: MongoDataService,
    private dialog: MatDialog, private notifyMeService: NotifyMeService, private ds: DataService) { }

  ngOnInit(): void {
    debugger
    this.headerService.setActiveURl('/TodaysVisits');
    this.headerService.setTitle("Today's Visits");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.UserType = localStorage.getItem("UserType");
    this.VData = [];
    this.DataType = "1";
    this.TokenNo = localStorage.getItem("TokenNo");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldName = params.get('FieldName');
      this.FieldValue = params.get('FieldValue');
      this.UserID = params.get('UserID');
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "todayorderbooking",
      "actiondesc": "today order booking" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    debugger;
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = '0';
    this.input.Datatype = this.DataType;
    this.input.UserID = this.UserID;
    this.mds.getTodaysOrder(this.input, this.TokenNo, this.device, this.deviceType, 'Todays Visits').subscribe(res => {
      this.VData = res;
      debugger;
    });
  }
  RowClick(paraname: any, paravalue: any): void {
    debugger
    paraname = "ab"
    if (paraname != "pc") {
      this.router.navigate(['/home/TodaysVisitsOutletListDetails']);
    }
  }
  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;
          this.loadData();
        }
      });
    }
  }


}
