<div class="container-fluid p-0">
    <div class="row mt-5">
        <div class="col-8">
            <i class="fa fa-caret-left">&nbsp;&nbsp;
                <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India':
                    FieldValue |titlecase}}</span></i>
        </div>
        <div class="col-4 text-right">
            <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                    class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
                style="color: #724e8c;font-weight:400 ;font-size: 0.8125rem;">Filter</span>
        </div>
    </div>
    <br>

    <div class="row mt-4">
        <div class="col-8 p-0">
            <span class="Head_text ms-1">Chandan Value Mart</span> 
            <span class="ms-1" style="font-size: 14px;
    font-weight: 200;">146122</span>
        </div>
        <div class="col-4 p-0" style="font-weight: 500;">
            <span>
                Right Visited<img class="mb-1" src="../../../assets/rightVisitedIcon.svg">
            </span> 

            <!-- <span>
                Wrong Visited<img class="mb-1" src="../../../assets/wrongVisitedIcon.svg">
            </span> 

            <span>
                No Visited<img class="mb-1" src="../../../assets/notVisitedIcon.svg">
            </span>  -->

        </div>
    </div>
    <hr class="hr_cust">

    <br><br><br><br><br><br><br>
</div>