import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import {Component, Injectable,OnInit,Inject} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import { FilterData, ResMasterdata, Resultdata } from 'src/app/models/kpi-models';
import { EncryptionService } from 'src/app/service/encryption.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputPara } from 'src/app/models/kpi-models';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})

export class FilterComponent   {
  shouldRun:boolean=true;
  UserType: any;
  IsRDValue: boolean=false;
  resMasterdata:ResMasterdata[] =[];
  menu1:any;
  menu2:any;
  menu3:any;
  menu4:any;
  menu5:any;
  menu6:any;
  rightList: any;
  HighlitedMenu: any;
  resultFieldName:string="";
  resultFieldValue:string="";
  resultdata:Resultdata=new Resultdata();
  visitedmenu1: boolean=false;
  visitedmenu2: boolean=false;
  visitedmenu3: boolean=false;
  visitedmenu4: boolean=false;
  visitedmenu5: boolean=false;
  MenuUpTo: number=5;
  input:InputPara=new InputPara();
  constructor(private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
     private router: Router,
      private es: EncryptionService,
       private mds: MongoDataService,
       public dialogRef: MatDialogRef<FilterComponent>,
       @Inject(MAT_DIALOG_DATA) public filterData:FilterData) {
        this.HighlitedMenu='';
        debugger;
    this.loadData();

  }
loadData(): void {
  debugger;
  this.menu1='Branch';
  this.menu2='Region';
  this.menu3='ASM Area';
  this.menu4='SO';
  this.menu5='RD';
  this.rightList=null;
  this.visitedmenu1=false;
  this.visitedmenu2=false;
  this.visitedmenu3=false;
  this.visitedmenu4=false;
  this.visitedmenu5=false;
  this.MenuUpTo=Number(this.filterData.MenuUpTo);
  this.input.FieldName=this.filterData.FieldName;
    this.input.FieldValue=this.filterData.FieldValue;
    this.input.TagName=this.filterData.MenuUpTo;
    this.input.Datatype='0';
    this.input.UserID=this.filterData.UserID;
  this.mds.GetMenuData(this.input).subscribe(res => {
    this.resMasterdata = res as ResMasterdata [];
    if(this.filterData.FieldName.toUpperCase()=='ALLINDIA'){
      this.HighlitedMenu=this.menu1;
      this.rightList = [...new Set(res.map((bill: { branch: any; }) => bill.branch))].sort();
    }
    else if(this.filterData.FieldName.toUpperCase()=='BRANCH'){
      this.visitedmenu1=true;
      this.HighlitedMenu=this.menu1;
      this.rightList = [...new Set(res.map((bill: { branch: any; }) => bill.branch))].sort();
    }
    else if(this.filterData.FieldName.toUpperCase()=='REGION'){
      this.visitedmenu1=true;
    //  this.visitedmenu2=true;
     // this.menu3=this.filterData.FieldValue;
      this.HighlitedMenu=this.menu2;
      this.rightList = [...new Set(res.map((bill: { region: any; }) => bill.region))].sort();
    }
    else if(this.filterData.FieldName.toUpperCase()=='ASMAREA'){

      this.visitedmenu1=true;
      this.visitedmenu2=true;
  //    this.visitedmenu3=true;
      //this.menu4=this.filterData.FieldValue;
      this.HighlitedMenu=this.menu3;
      this.rightList = [...new Set(res.map((bill: { asmarea: any; }) => bill.asmarea))].sort();
    }
    else if(this.filterData.FieldName.toUpperCase()=='SOTERRITORY'){

      this.visitedmenu1=true;
      this.visitedmenu2=true;
      this.visitedmenu3=true;
      this.HighlitedMenu=this.menu4;
      this.rightList = [...new Set(res.map((bill: { soterritory: any; }) => bill.soterritory))].sort();
      this.menu1=res[0].branch;
      this.menu2=res[0].region;
      this.menu3=res[0].asmarea;

    }
    else if(this.filterData.FieldName.toUpperCase()=='RD'){
      debugger;
      this.visitedmenu4=true;
     // this.menu6=this.filterData.FieldValue;
      this.HighlitedMenu=this.menu5;
      // debugger;
      this.rightList = [...new Set(res.map((bill: { rdcode: any; }) => bill.rdcode))].sort();
    }
  });
}

onCheckboxChange(e:any):void {
  debugger;
  if (e.target.checked) {

    if(this.HighlitedMenu.toUpperCase()=='BRANCH'){
      this.resultFieldName="BRANCH";
      this.menu1 = e.target.value ;
      this.resultFieldValue=e.target.value ;
    }
    else if(this.HighlitedMenu.toUpperCase()=='REGION'){
      this.resultFieldName="REGION";
        this.menu2 = e.target.value ;
        this.resultFieldValue=e.target.value ;
    }
    else if(this.HighlitedMenu.toUpperCase()=='ASMAREA' || this.HighlitedMenu.toUpperCase()=='ASM AREA'){
      this.resultFieldName="ASMAREA";
      this.menu3 = e.target.value ;
      this.resultFieldValue=e.target.value ;
    }
    else if(this.HighlitedMenu.toUpperCase()=='SOTERRITORY'|| this.HighlitedMenu.toUpperCase()=='SO' || this.HighlitedMenu.toUpperCase()=='SE'){
      this.resultFieldName="SOTERRITORY";
      this.menu4 =e.target.value ;
      this.resultFieldValue=e.target.value ;
    }
    else if(this.HighlitedMenu.toUpperCase()=='RD'){
      this.resultFieldName="RD";
      this.menu5 =e.target.value ;
      this.resultFieldValue=e.target.value ;
    }
  }
}
Menu1Click(){
  debugger;
  if(!this.visitedmenu1){
    this.visitedmenu2=false;
    this.visitedmenu3=false;
    this.visitedmenu4=false;
    this.visitedmenu5=false;
 this.HighlitedMenu="Branch";
  this.rightList = [...new Set(this.resMasterdata.map((bill: { branch: any; }) => bill.branch))].sort();
}
}
Menu2Click(){
  if(!this.visitedmenu2){
    this.visitedmenu1=true;
    this.visitedmenu3=false;
    this.visitedmenu4=false;
    this.visitedmenu5=false;
  if(this.menu1.toUpperCase()!='BRANCH'){

    this.HighlitedMenu="Region";
  let tempasmAreaList = this.resMasterdata.filter(key=> key['branch'].startsWith(this.menu1));
  this.rightList = [...new Set(tempasmAreaList.map((bill: { region: any; }) => bill.region))].sort();
}
  }
}
Menu3Click(){
  debugger;
  if(!this.visitedmenu3){
    this.visitedmenu2=true;
    this.visitedmenu4=false;
    this.visitedmenu5=false;
  if(this.menu2.toUpperCase()!='REGION'){

    this.HighlitedMenu="Asmarea";
  let tempasmAreaList = this.resMasterdata.filter(key=> key['region'].startsWith(this.menu2));
  this.rightList = [...new Set(tempasmAreaList.map((bill: { asmarea: any; }) => bill.asmarea))].sort();
}
  }
}
Menu4Click(){
  debugger;
  if(!this.visitedmenu4){
    this.visitedmenu3=true;
    this.visitedmenu5=false;
    if(this.menu3.toUpperCase()!='ASMAREA'){

    this.HighlitedMenu="soterritory";
  let tempasmAreaList = this.resMasterdata.filter(key=> key['asmarea'].startsWith(this.menu3));
  this.rightList = [...new Set(tempasmAreaList.map((bill: { soterritory: any; }) => bill.soterritory))].sort();
}
  }
}
Menu5Click(){
  debugger;
  if(!this.visitedmenu5){
    this.visitedmenu4=true;
    if((this.HighlitedMenu=="soterritory")&&(this.menu4.toUpperCase()!='SOTERRITORY')){
  //  debugger;
    this.HighlitedMenu="rd";
    
    let tempasmAreaList = this.resMasterdata.filter(key=> key['soterritory'].startsWith(this.menu4));
   
    this.rightList = [...new Set(tempasmAreaList.map(T => (T.rdname + ' : ' + T.rdcode)))].sort();
  // let rdcodeno=Number(this.menu4);
  // let tempasmAreaList = this.resMasterdata.filter(key=> key['rdcode']==this.menu4);
  }
  
}
}
 onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(): void {
     debugger;
    if(this.resultFieldName=='RD'){
      this.resultdata.FieldName= this.resultFieldName;
      this.resultdata.FieldValue=  this.resultFieldValue.substring(this.resultFieldValue.indexOf(':')+1).trim();
    }
    else {
    this.resultdata.FieldName= this.resultFieldName=='' ? this.filterData.FieldName : this.resultFieldName;
    this.resultdata.FieldValue=  this.resultFieldValue=='' ? this.filterData.FieldValue : this.resultFieldValue;
    }
    this.dialogRef.close(this.resultdata);
  }
  ResetMenus(){
    debugger;
    this.loadData();
  }
}


  /* Get the parent node of a node */
